//备机、配件申请表格
<template>
  <el-table border :data="tableData" height="100%">
    <el-table-column align="center" label="序号" type="index" width="60" />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="orderCode"
      label="申请单号"
      width="180"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="U8单号"
      width="150"
      :formatter="({ u8AllotOrderCode }) => u8AllotOrderCode || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="proposerName"
      label="申请人"
      width="100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="applyDepartmentName"
      label="申请人部门"
      width="100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="日期"
      width="120"
    >
      <template slot-scope="{ row }">
        {{ row.applyTime | date('YYYY-mm-dd') }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="clientName"
      label="客户名称"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="预计归还日期"
      width="120"
    >
      <template slot-scope="{ row }">
        {{ row.expectedReturnTime | date('YYYY-mm-dd') }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="consignee"
      label="收货人"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="consigneeMobile"
      label="联系电话"
      width="120"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="code"
      label="料号"
      width="120"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="name"
      label="物品名称"
      width="120"
    />
	<el-table-column
	  header-align="center"
	  align="center"
	  show-overflow-tooltip
	  prop="num"
	  label="数量"
	  width="80"
	/>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="auditStatusStr"
      label="审批状态"
    />
    <el-table-column
      header-align="center"
      label="操作"
      width="60"
      fixed="right"
    >
      <template slot-scope="{ row }">
        <el-button
          size="medium"
          type="text"
          @click="toDetail({ ...row, orderType: orderType })"
        >
          详情
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import tableTypeMixins from './tableTypeMixins.js'
export default {
  mixins: [tableTypeMixins],
  props: {
    orderType: {
      type: Boolean,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped></style>
