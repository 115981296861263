//服务收费表格
<template>
  <el-table border :data="tableData" height="100%">
    <el-table-column align="center" label="序号" type="index" width="60" />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="orderCode"
      label="申请单号"
      width="180"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="U8销售单号"
      width="150"
      :formatter="({ u8SellCode }) => u8SellCode || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="订单日期"
      width="120"
    >
      <template slot-scope="{ row }">
        {{ row.orderTime | date('YYYY-mm-dd') }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="关联工单号"
      width="150"
      :formatter="({ workOrderCode }) => workOrderCode || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="销售类型"
      width="100"
    >
      <template slot-scope="{ row }">
        {{ row.sellType | commonFilter($constant.sellType) }}
      </template>
    </el-table-column>
    <!-- <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="业务类型"
      width="100"
    >
      <template slot-scope="{ row }">
        {{ row.businessType | commonFilter($constant.businessType) }}
      </template>
    </el-table-column> -->
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="salesDepartmentName"
      label="销售部门"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="salesmanName"
      label="业务员"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="保修方式"
    >
      <template slot-scope="{ row }">
        {{ row.maintenanceType | commonFilter($constant.maintenanceType) }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="clientName"
      label="客户名称"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="付款方式"
    >
      <template slot-scope="{ row }">
        {{ row.payWay | commonFilter($constant.payWay) }}
      </template>
    </el-table-column>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="联系人"
      :formatter="({ linkman }) => linkman || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="联系人手机"
      width="120"
      :formatter="({ linkmanPhone }) => linkmanPhone || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="联系人地址"
      width="120"
      :formatter="({ linkmanAddress }) => linkmanAddress || '-'"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="code"
      label="料号"
      width="120"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="name"
      label="物品名称"
      width="120"
    />
	<el-table-column
	  header-align="center"
	  align="center"
	  show-overflow-tooltip
	  prop="num"
	  label="数量"
	  width="80"
	/>
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      label="总价"
      :formatter="({ totalAmount }) => totalAmount / 100"
    />
    <el-table-column
      header-align="center"
      align="center"
      show-overflow-tooltip
      prop="auditStatusStr"
      label="审批状态"
    />
    <el-table-column
      header-align="center"
      show-overflow-tooltip
      label="操作"
      width="60"
      fixed="right"
    >
      <template slot-scope="{ row }">
        <el-button
          size="medium"
          type="text"
          @click="toDetail({ ...row, orderType: 4 })"
        >
          详情
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import tableTypeMixins from './tableTypeMixins.js'
export default {
  mixins: [tableTypeMixins]
}
</script>

<style lang="scss" scoped></style>
